import React from "react";
import LottieView from "react-lottie";

interface Props {
  style?: {};
  size?: number;
  white?: boolean;
}

const Loading = ({ style, size = 40, white }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("./loading-white.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div style={{ padding: 15, alignSelf: "center", ...style }}>
      {white ? (
        <LottieView
          isClickToPauseDisabled
          height={size}
          width={size}
          options={{
            ...defaultOptions,
            animationData: require("./loading-white.json"),
          }}
        />
      ) : (
        <LottieView
          isClickToPauseDisabled
          height={size}
          width={size}
          options={{
            ...defaultOptions,
            animationData: require("./loading.json"),
          }}
        />
      )}
    </div>
  );
};

export default Loading;
