import React from "react";
import Media from "react-media";
import { generateMedia } from "styled-media-query";
import { MOBILE, DESKTOP } from "./sizes";

const media = generateMedia({
  desktop: DESKTOP,
  mobile: MOBILE,
});

export { media };
export default function MediaComponent({ children }) {
  return (
    <Media
      queries={{
        mobile: "(max-width: 600px)",
        desktop: "(min-width: 601px) and (max-width: 1200px)",
      }}
    >
      {children}
    </Media>
  );
}
