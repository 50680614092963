import React from "react";
import firebase from "firebase/app";
import { HelmetProvider } from "react-helmet-async";
// hocs
import withInitialize from "hocs/withInitialize";
import withLoading from "hocs/withLoading";
import withStyles from "hocs/withStyles";
// redux
import { createStore } from "redux";
import { Provider } from "react-redux";
// import logger from "redux-logger";
import rootReducer from "reduxModules/index";
// components
import Root from "./Root";

const firebaseConfig = {
  apiKey: "AIzaSyA7M-QJ_v8MjzPjGPaEEa_4MxNeri3F5Jg",
  authDomain: "bibly-ec9c4.firebaseapp.com",
  databaseURL: "https://bibly-ec9c4.firebaseio.com",
  projectId: "bibly-ec9c4",
  storageBucket: "bibly-ec9c4.appspot.com",
  messagingSenderId: "147974630931",
  appId: "1:147974630931:web:2865d33ba9398db0db19e6",
  measurementId: "G-XDF3HYB48B",
};

const App = (props) => {
  // initialize firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }
  // redux store
  const store = createStore(
    rootReducer
    // applyMiddleware(logger)
  );

  // wrapped hocs
  const hocs = [withStyles, withLoading, withInitialize];
  const ComponentWithHOCs = hocs.reduce(
    (WrappedComponent: any, withHOC: any) => {
      return withHOC(WrappedComponent);
    },
    Root
  );

  return (
    <HelmetProvider>
      <Provider store={store}>
        <ComponentWithHOCs {...props} />
      </Provider>
    </HelmetProvider>
  );
};

export default App;
