import React from "react";
import LottieView from "react-lottie";

interface Props {
  style?: {};
  size?: number;
}

const Loading = ({ style, size = 500 }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("./404.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div style={{ alignSelf: "center", ...style }}>
      <LottieView
        isClickToPauseDisabled
        height={size}
        width={size}
        options={defaultOptions}
      />
    </div>
  );
};

export default Loading;
