import React from "react";
import styled from "styled-components";
// styles
import { View, Icon } from "styles/index";
import * as sizes from "styles/sizes";
import * as colors from "styles/colors";

const BookHeader = () => {
  return (
    <Container alignCenter>
      <Icon
        alignCenter
        iconName="arrow-left-bold"
        onClick={() => console.log(11)}
        style={{
          paddingRight: 3,
          paddingLeft: sizes.SIDE_PADDING,
          height: "100%",
        }}
      />
    </Container>
  );
};

export default BookHeader;

const Container = styled(View)`
  height: ${sizes.HEADER_HEIGHT};
  background-color: ${colors.BACKGROUND_GREY};
`;
