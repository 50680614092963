import React from "react";
import styled from "styled-components";
// styles
import * as colors from "styles/colors";
import { H, P } from "styles/typography";
import { media } from "styles/media";
// types
import { Book } from "api/types";

interface Props {
  book: Book;
  index: number;
}
const BCurationCard = ({ book, index = 0 }: Props) => {
  return (
    <Container>
      <ImgContainer>
        <AbsoluteView
          style={{ zIndex: -2, backgroundColor: colors.BACKGROUND_GREY }}
        />
        <AbsoluteView style={{ zIndex: -1, left: "60px" }}>
          <H>0{index + 1}</H>
        </AbsoluteView>
        <BookImg src={book.bookImage} />
      </ImgContainer>
      <TextContainer>
        <H f28>{book.bookName}</H>
        <P f20 style={{ color: colors.GREY_DARK }}>
          {book.authorName}
        </P>
        <P f18 style={{ lineHeight: "28px" }}>
          {book.curationBookContent}
        </P>
      </TextContainer>
    </Container>
  );
};

export default BCurationCard;

const Container = styled.div`
  flex: 1 1 590px;
  max-width: 580px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  &:nth-child(odd) {
    margin-right: 40px;
    ${media.lessThan("desktop")`
      margin-right: 0;
    `}
  }
`;
const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 42px 0px 42px 0px;
  margin-bottom: 16px;
`;
const AbsoluteView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 200px;
    color: ${colors.BORDER_GREY};
    letter-spacing: 60px;
    ${media.lessThan("mobile")`
      font-size: 140px;
    `}
  }
`;
const BookImg = styled.img`
  width: 160px;
  height: 232px;
  border-radius: 10px;
  ${media.lessThan("mobile")`
    width: 112px;
    height: 162px;
    border-radius: 5px;
  `}
`;
const TextContainer = styled.div`
  h1 {
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 16px;
  }
  ${media.lessThan("mobile")`
      h1 {
        font-size: 24px;
        margin-bottom: 8px;
      }
      p {
        margin-bottom: 8px;
      }
    `}
`;
