import React from "react";
import styled from "styled-components";
import { View } from "styles/index";
// svgs
import { ReactComponent as Add } from "common/assets/svgs/icons/add.svg";
import { ReactComponent as ArrowUp } from "common/assets/svgs/icons/arrow-up.svg";
import { ReactComponent as ArrowUpWhite } from "common/assets/svgs/icons/arrow-up-white.svg";
import { ReactComponent as ArrowRightWhite } from "common/assets/svgs/icons/arrow-right-white.svg";
import { ReactComponent as ArrowRight } from "common/assets/svgs/icons/arrow-right.svg";
import { ReactComponent as ArrowRightGray } from "common/assets/svgs/icons/arrow-right-gray.svg";
import { ReactComponent as ArrowDown } from "common/assets/svgs/icons/arrow-down.svg";
import { ReactComponent as ArrowDownWhite } from "common/assets/svgs/icons/arrow-down-white.svg";
import { ReactComponent as ArrowLeft } from "common/assets/svgs/icons/arrow-left.svg";
import { ReactComponent as ArrowLeftGray } from "common/assets/svgs/icons/arrow-left-gray.svg";
import { ReactComponent as ArrowLeftBold } from "common/assets/svgs/icons/arrow-left-bold.svg";
import { ReactComponent as ArrowLeftBoldWhite } from "common/assets/svgs/icons/arrow-left-bold-white.svg";
import { ReactComponent as Alarm } from "common/assets/svgs/icons/alarm.svg";
import { ReactComponent as AladinOff } from "common/assets/svgs/icons/aladin-off.svg";
import { ReactComponent as AladinOn } from "common/assets/svgs/icons/aladin-on.svg";
import { ReactComponent as BookWhite } from "common/assets/svgs/icons/book-white.svg";
import { ReactComponent as BookGray } from "common/assets/svgs/icons/book-gray.svg";
import { ReactComponent as BookAdd } from "common/assets/svgs/icons/book-add.svg";
import { ReactComponent as BookClipAdd } from "common/assets/svgs/icons/book-clip-add.svg";
import { ReactComponent as BuyCart } from "common/assets/svgs/icons/buy-cart.svg";
import { ReactComponent as BookCalendarAdd } from "common/assets/svgs/icons/book-calendar-add.svg";
import { ReactComponent as BookCheck } from "common/assets/svgs/icons/book-check.svg";
import { ReactComponent as Bookmark } from "common/assets/svgs/icons/bookmark.svg";
import { ReactComponent as BookmarkGray } from "common/assets/svgs/icons/bookmark-gray.svg";
import { ReactComponent as BookmarkWhite } from "common/assets/svgs/icons/bookmark-white.svg";
import { ReactComponent as CommentGray } from "common/assets/svgs/icons/comment-gray.svg";
import { ReactComponent as Close } from "common/assets/svgs/icons/close.svg";
import { ReactComponent as CloseWhite } from "common/assets/svgs/icons/close-white.svg";
import { ReactComponent as CloseGray } from "common/assets/svgs/icons/close-gray.svg";
import { ReactComponent as Camera } from "common/assets/svgs/icons/camera.svg";
import { ReactComponent as Calendar } from "common/assets/svgs/icons/calendar.svg";
import { ReactComponent as CheckBlue } from "common/assets/svgs/icons/check-blue.svg";
import { ReactComponent as CheckWhite } from "common/assets/svgs/icons/check-white.svg";
import { ReactComponent as CheckBoxOn } from "common/assets/svgs/icons/check-box-on.svg";
import { ReactComponent as CheckBoxOff } from "common/assets/svgs/icons/check-box-off.svg";
import { ReactComponent as CardGray } from "common/assets/svgs/icons/card-gray.svg";
import { ReactComponent as CardWhite } from "common/assets/svgs/icons/card-white.svg";
import { ReactComponent as DeliveryGray } from "common/assets/svgs/icons/delivery-gray.svg";
import { ReactComponent as DeliveryWhite } from "common/assets/svgs/icons/delivery-white.svg";
import { ReactComponent as EyeOff } from "common/assets/svgs/icons/eye-off.svg";
import { ReactComponent as Eye } from "common/assets/svgs/icons/eye.svg";
import { ReactComponent as Female } from "common/assets/svgs/icons/female.svg";
import { ReactComponent as FemaleWhite } from "common/assets/svgs/icons/female-white.svg";
import { ReactComponent as FolderAdd } from "common/assets/svgs/icons/folder-add.svg";
import { ReactComponent as FolderAddGray } from "common/assets/svgs/icons/folder-add-gray.svg";
import { ReactComponent as FolderSettingGray } from "common/assets/svgs/icons/folder-setting-gray.svg";
import { ReactComponent as Feed } from "common/assets/svgs/icons/feed.svg";
import { ReactComponent as FeedGray } from "common/assets/svgs/icons/feed-gray.svg";
import { ReactComponent as Filter } from "common/assets/svgs/icons/filter.svg";
import { ReactComponent as Gallery } from "common/assets/svgs/icons/gallery.svg";
import { ReactComponent as Heart } from "common/assets/svgs/icons/heart.svg";
import { ReactComponent as HeartOff } from "common/assets/svgs/icons/heart-off.svg";
import { ReactComponent as HeartOffWhite } from "common/assets/svgs/icons/heart-off-white.svg";
import { ReactComponent as Home } from "common/assets/svgs/icons/home.svg";
import { ReactComponent as HomeGray } from "common/assets/svgs/icons/home-gray.svg";
import { ReactComponent as InterparkOff } from "common/assets/svgs/icons/interpark-off.svg";
import { ReactComponent as InterparkOn } from "common/assets/svgs/icons/interpark-on.svg";
import { ReactComponent as KyoboOff } from "common/assets/svgs/icons/kyobo-off.svg";
import { ReactComponent as KyoboOn } from "common/assets/svgs/icons/kyobo-on.svg";
import { ReactComponent as LockGray } from "common/assets/svgs/icons/lock-gray.svg";
import { ReactComponent as Library } from "common/assets/svgs/icons/library.svg";
import { ReactComponent as LibraryGray } from "common/assets/svgs/icons/library-gray.svg";
import { ReactComponent as Male } from "common/assets/svgs/icons/male.svg";
import { ReactComponent as MaleWhite } from "common/assets/svgs/icons/male-white.svg";
import { ReactComponent as MoreVertical } from "common/assets/svgs/icons/more-vertical.svg";
import { ReactComponent as MoreVerticalGray } from "common/assets/svgs/icons/more-vertical-gray.svg";
import { ReactComponent as MoveGray } from "common/assets/svgs/icons/move-gray.svg";
import { ReactComponent as OrderGray } from "common/assets/svgs/icons/order-gray.svg";
import { ReactComponent as OrderWhite } from "common/assets/svgs/icons/order-white.svg";
import { ReactComponent as Play } from "common/assets/svgs/icons/play.svg";
import { ReactComponent as QuotesStartGray } from "common/assets/svgs/icons/quotes-start-gray.svg";
import { ReactComponent as QuotesEndGray } from "common/assets/svgs/icons/quotes-end-gray.svg";
import { ReactComponent as Question } from "common/assets/svgs/icons/question.svg";
import { ReactComponent as QuestionWhite } from "common/assets/svgs/icons/question-white.svg";
import { ReactComponent as ReturnWhite } from "common/assets/svgs/icons/return-white.svg";
import { ReactComponent as ReturnGray } from "common/assets/svgs/icons/return-gray.svg";
import { ReactComponent as RadioOn } from "common/assets/svgs/icons/radio-on.svg";
import { ReactComponent as RadioOff } from "common/assets/svgs/icons/radio-off.svg";
import { ReactComponent as Refresh } from "common/assets/svgs/icons/refresh.svg";
import { ReactComponent as Setting } from "common/assets/svgs/icons/setting.svg";
import { ReactComponent as Star } from "common/assets/svgs/icons/star.svg";
import { ReactComponent as StarOff } from "common/assets/svgs/icons/star-off.svg";
import { ReactComponent as Search } from "common/assets/svgs/icons/search.svg";
import { ReactComponent as Store } from "common/assets/svgs/icons/store.svg";
import { ReactComponent as StoreGray } from "common/assets/svgs/icons/store-gray.svg";
import { ReactComponent as Share } from "common/assets/svgs/icons/share.svg";
import { ReactComponent as ShareWhite } from "common/assets/svgs/icons/share-white.svg";
import { ReactComponent as ShoppingBagGray } from "common/assets/svgs/icons/shopping-bag-gray.svg";
import { ReactComponent as ShoppingBagWhite } from "common/assets/svgs/icons/shopping-bag-white.svg";
import { ReactComponent as ShoppingBag } from "common/assets/svgs/icons/shopping-bag.svg";
import { ReactComponent as ShoppingBagDisabled } from "common/assets/svgs/icons/shopping-bag-disabled.svg";
import { ReactComponent as Trash } from "common/assets/svgs/icons/trash.svg";
import { ReactComponent as TriAngleArrowRightGray } from "common/assets/svgs/icons/triangle-arrow-right-gray.svg";
import { ReactComponent as TriAngleArrowDown } from "common/assets/svgs/icons/triangle-arrow-down.svg";
import { ReactComponent as TriAngleArrowDownBlue } from "common/assets/svgs/icons/triangle-arrow-down-blue.svg";
import { ReactComponent as TriAngleArrowUpRed } from "common/assets/svgs/icons/triangle-arrow-up-red.svg";
import { ReactComponent as ViewThumbGray } from "common/assets/svgs/icons/view-thumb-gray.svg";
import { ReactComponent as WritingGray } from "common/assets/svgs/icons/writing-gray.svg";
import { ReactComponent as Yes24On } from "common/assets/svgs/icons/yes24-on.svg";
import { ReactComponent as Yes24Off } from "common/assets/svgs/icons/yes24-off.svg";

interface Props {
  iconName: string;
  size?: number;
  style?: {};
  onClick?: any;
  [key: string]: any;
}
function Icon({ iconName, size = 24, style, onClick, ...props }: Props) {
  const iconStyle = {
    width: size,
    height: size,
  };
  function renderIcon() {
    switch (iconName) {
      case "add":
        return <Add style={iconStyle} />;
      case "arrow-up":
        return <ArrowUp style={iconStyle} />;
      case "arrow-up-white":
        return <ArrowUpWhite style={iconStyle} />;
      case "arrow-down":
        return <ArrowDown style={iconStyle} />;
      case "arrow-down-white":
        return <ArrowDownWhite style={iconStyle} />;
      case "arrow-left":
        return <ArrowLeft style={iconStyle} />;
      case "arrow-left-gray":
        return <ArrowLeftGray style={iconStyle} />;
      case "arrow-left-bold":
        return <ArrowLeftBold style={iconStyle} />;
      case "arrow-left-bold-white":
        return <ArrowLeftBoldWhite style={iconStyle} />;
      case "arrow-right-white":
        return <ArrowRightWhite style={iconStyle} />;
      case "arrow-right":
        return <ArrowRight style={iconStyle} />;
      case "arrow-right-gray":
        return <ArrowRightGray style={iconStyle} />;
      case "alarm":
        return <Alarm style={iconStyle} />;
      case "aladin-off":
        return <AladinOff style={iconStyle} />;
      case "aladin-on":
        return <AladinOn style={iconStyle} />;
      case "book-gray":
        return <BookGray style={iconStyle} />;
      case "book-white":
        return <BookWhite style={iconStyle} />;
      case "book-add":
        return <BookAdd style={iconStyle} />;
      case "buy-cart":
        return <BuyCart style={iconStyle} />;
      case "book-clip-add":
        return <BookClipAdd style={iconStyle} />;
      case "book-calendar-add":
        return <BookCalendarAdd style={iconStyle} />;
      case "book-check":
        return <BookCheck style={iconStyle} />;
      case "bookmark":
        return <Bookmark style={iconStyle} />;
      case "bookmark-gray":
        return <BookmarkGray style={iconStyle} />;
      case "bookmark-white":
        return <BookmarkWhite style={iconStyle} />;
      case "calendar":
        return <Calendar style={iconStyle} />;
      case "close":
        return <Close style={iconStyle} />;
      case "close-white":
        return <CloseWhite style={iconStyle} />;
      case "close-gray":
        return <CloseGray style={iconStyle} />;
      case "camera":
        return <Camera style={iconStyle} />;
      case "comment-gray":
        return <CommentGray style={iconStyle} />;
      case "check-box-on":
        return <CheckBoxOn style={iconStyle} />;
      case "check-box-off":
        return <CheckBoxOff style={iconStyle} />;
      case "card-gray":
        return <CardGray style={iconStyle} />;
      case "card-white":
        return <CardWhite style={iconStyle} />;
      case "check-blue":
        return <CheckBlue style={iconStyle} />;
      case "check-white":
        return <CheckWhite style={iconStyle} />;
      case "delivery-gray":
        return <DeliveryGray style={iconStyle} />;
      case "delivery-white":
        return <DeliveryWhite style={iconStyle} />;
      case "eye":
        return <Eye style={iconStyle} />;
      case "eye-off":
        return <EyeOff style={iconStyle} />;
      case "female":
        return <Female style={iconStyle} />;
      case "female-white":
        return <FemaleWhite style={iconStyle} />;
      case "feed":
        return <Feed style={iconStyle} />;
      case "feed-gray":
        return <FeedGray style={iconStyle} />;
      case "folder-add":
        return <FolderAdd style={iconStyle} />;
      case "folder-add-gray":
        return <FolderAddGray style={iconStyle} />;
      case "folder-setting-gray":
        return <FolderSettingGray style={iconStyle} />;
      case "filter":
        return <Filter style={iconStyle} />;
      case "gallery":
        return <Gallery style={iconStyle} />;
      case "home":
        return <Home style={iconStyle} />;
      case "home-gray":
        return <HomeGray style={iconStyle} />;
      case "heart":
        return <Heart style={iconStyle} />;
      case "heart-off":
        return <HeartOff style={iconStyle} />;
      case "heart-off-white":
        return <HeartOffWhite style={iconStyle} />;
      case "interpark-off":
        return <InterparkOff style={iconStyle} />;
      case "interpark-on":
        return <InterparkOn style={iconStyle} />;
      case "kyobo-on":
        return <KyoboOn style={iconStyle} />;
      case "kyobo-off":
        return <KyoboOff style={iconStyle} />;
      case "library":
        return <Library style={iconStyle} />;
      case "library-gray":
        return <LibraryGray style={iconStyle} />;
      case "lock-gray":
        return <LockGray style={iconStyle} />;
      case "male":
        return <Male style={iconStyle} />;
      case "male-white":
        return <MaleWhite style={iconStyle} />;
      case "more-vertical":
        return <MoreVertical style={iconStyle} />;
      case "more-vertical-gray":
        return <MoreVerticalGray style={iconStyle} />;
      case "move-gray":
        return <MoveGray style={iconStyle} />;
      case "order-gray":
        return <OrderGray style={iconStyle} />;
      case "order-white":
        return <OrderWhite style={iconStyle} />;
      case "play":
        return <Play style={iconStyle} />;
      case "quotes-start-gray":
        return <QuotesStartGray style={iconStyle} />;
      case "quotes-end-gray":
        return <QuotesEndGray style={iconStyle} />;
      case "question":
        return <Question style={iconStyle} />;
      case "question-white":
        return <QuestionWhite style={iconStyle} />;
      case "radio-on":
        return <RadioOn style={iconStyle} />;
      case "radio-off":
        return <RadioOff style={iconStyle} />;
      case "refresh":
        return <Refresh style={iconStyle} />;
      case "return-gray":
        return <ReturnGray style={iconStyle} />;
      case "return-white":
        return <ReturnWhite style={iconStyle} />;
      case "setting":
        return <Setting style={iconStyle} />;
      case "star":
        return <Star style={iconStyle} />;
      case "star-off":
        return <StarOff style={iconStyle} />;
      case "share":
        return <Share style={iconStyle} />;
      case "share-white":
        return <ShareWhite style={iconStyle} />;
      case "store":
        return <Store style={iconStyle} />;
      case "store-gray":
        return <StoreGray style={iconStyle} />;
      case "shopping-bag-disabled":
        return <ShoppingBagDisabled style={iconStyle} />;
      case "shopping-bag":
        return <ShoppingBag style={iconStyle} />;
      case "shopping-bag-gray":
        return <ShoppingBagGray style={iconStyle} />;
      case "shopping-bag-white":
        return <ShoppingBagWhite style={iconStyle} />;
      case "search":
        return <Search style={iconStyle} />;
      case "trash":
        return <Trash style={iconStyle} />;
      case "triangle-arrow-right-gray":
        return <TriAngleArrowRightGray style={iconStyle} />;
      case "triangle-arrow-down":
        return <TriAngleArrowDown style={iconStyle} />;
      case "triangle-arrow-down-blue":
        return <TriAngleArrowDownBlue style={iconStyle} />;
      case "triangle-arrow-up-red":
        return <TriAngleArrowUpRed style={iconStyle} />;
      case "view-thumb-gray":
        return <ViewThumbGray style={iconStyle} />;
      case "writing-gray":
        return <WritingGray style={iconStyle} />;
      case "yes24-on":
        return <Yes24On style={iconStyle} />;
      case "yes24-off":
        return <Yes24Off style={iconStyle} />;
      default:
        return null;
    }
  }
  return (
    <>
      {onClick ? (
        <Button onClick={onClick} style={style} {...props}>
          {renderIcon()}
        </Button>
      ) : (
        <View style={style} {...props}>
          {renderIcon()}
        </View>
      )}
    </>
  );
}

export default Icon;

const Button = styled(View)`
  cursor: pointer;
`;
