export const MOBILE = "600px";

export const DESKTOP = "1200px";

export const HEADER_HEIGHT = "54px";

export const FOOTER_HEIGHT = "80px";

export const SIDE_PADDING = "20px";

export const BOOK_VERTICAL_RATE = 1.44;

export const BANNER_VERTICAL_RATE = 1.25;

export const EVENT_BANNER_VERTICAL_RATE = 0.625;

export const CURATION_VERTICAL_RATE = 0.52;
