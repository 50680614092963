// apis
import useAPI from "../useAPI";
// types
import {
  BookInfo,
  Comment,
  BookCategory,
  User,
  BiblyCuration,
  BookMyInfo,
  Author,
} from "../types";

export interface GetBookRes {
  bookInfo: BookInfo;
  bookMyInfo: BookMyInfo;
  authorInfo: Author[];
}
export interface GetBookEtcRes {
  categoryList: BookCategory[];
  userList: User[];
  bCurationList: BiblyCuration[];
}
export interface GetCommentsRes {
  commentList: Comment[];
  totalCount: number;
}
export interface GetBookCommentParams {
  bookId: number;
  page: number;
  pagePerCount: number;
}
export interface GetAuthorAPI {
  authorList: AuthorList[];
}
export interface AuthorList {
  authorId: number;
  authorType: string;
  description: string;
  authorImage: string;
  authorName: string;
  writingBooks: WritingBooks[];
}
export interface WritingBooks {
  authorId: number;
  authorType: string;
  bookId: number;
  bookName: string;
  bookSubName: string;
  authorName: string;
  publishDate: string;
  publisherName: string;
  price: number;
  discountRate: string;
  scoreAvg: string;
  scoreCount: number;
  cartRegisteredYn: number;
  bookshelfRegisteredYn: number;
}

export default function useBookAPI() {
  const { getHandler } = useAPI();

  // 책 상세 호출
  async function getBookAPI(
    bookId: number,
    params: {
      bookCondition: number;
    }
  ): Promise<GetBookRes> {
    try {
      const res = await getHandler(`/book/${bookId}`, params);
      return res.data.result;
    } catch (error) {
      throw error;
    }
  }
  // 책 상세 기타 정보 호출
  async function getBookEtcAPI(bookId: number): Promise<GetBookEtcRes> {
    try {
      const res = await getHandler(`/book/${bookId}/etc`);
      return res.data.result;
    } catch (error) {
      throw error;
    }
  }

  // 코멘트 호출
  async function getBookCommentsAPI(
    params: GetBookCommentParams
  ): Promise<GetCommentsRes> {
    try {
      const res = await getHandler(`/book/comments`, params);
      return res.data.result;
    } catch (error) {
      throw error;
    }
  }

  // 저자 상세
  async function getAuthorAPI(bookId: number): Promise<GetAuthorAPI> {
    try {
      const res = await getHandler(`/book/${bookId}/author`);
      return res.data.result;
    } catch (error) {
      throw error;
    }
  }

  return {
    getBookAPI,
    getBookEtcAPI,
    getBookCommentsAPI,
    getAuthorAPI,
  };
}
