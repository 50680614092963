import React from "react";
import GlobalStyle from "styles/base";

const withStyles = (WrappedComponent: any) => (props: any) => {
  return (
    <>
      <GlobalStyle />
      <WrappedComponent {...props} />
    </>
  );
};

export default withStyles;
