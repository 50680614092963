import React from "react";
import styled from "styled-components";
// styles
import { ReactComponent as Logo } from "common/assets/svgs/bibly-logo.svg";
import { View } from "styles/index";
import { media } from "styles/media";

const Header = () => {
  return (
    <Container center>
      <StyledLogo />
    </Container>
  );
};

export default Header;

const Container = styled(View)`
  height: 180px;
  ${media.lessThan("desktop")`
    height: 140px;
  `}
  ${media.lessThan("mobile")`
    height: 100px;
  `}
`;
const StyledLogo = styled(Logo)`
  width: 120px;
  height: 40px;
  ${media.lessThan("mobile")`
    width: 90px;
    height: 30px;
  `}
`;
