import React, { useState, useEffect, useCallback } from "react";
// api
import useBookAPI, { GetBookRes, GetBookEtcRes } from "api/book/useBookAPI";
// redux
import useLoadingRedux from "reduxModules/loading/useLoadingRedux";
// hooks
import useQuery from "hooks/useQuery";
// components
import BookInfo from "./BookInfo";
// styles
import { Layout, View } from "styles/index";
import * as colors from "styles/colors";
import * as sizes from "styles/sizes";

const Book = () => {
  const query = useQuery();
  const bookId = query?.id ? Number(query.id) : 0;

  const { loadingDispatch } = useLoadingRedux();

  const [bookData, setBookData] = useState<GetBookRes | null>(null);
  const [bookDataEtc, setBookDataEtc] = useState<GetBookEtcRes | null>(null);

  const { getBookAPI, getBookEtcAPI } = useBookAPI();

  const handleGetBookInfo = useCallback(async () => {
    try {
      loadingDispatch.start();
      const [bookData, bookDataEtc] = await Promise.all([
        getBookAPI(bookId, { bookCondition: 0 }),
        getBookEtcAPI(bookId),
      ]);
      setBookData(bookData);
      setBookDataEtc(bookDataEtc);
    } catch (error) {
      console.log(error);
    } finally {
      loadingDispatch.end();
    }
  }, [bookId]);

  useEffect(() => {
    handleGetBookInfo();
  }, []);

  return (
    <Layout
      headerType="book"
      style={{ backgroundColor: colors.BACKGROUND_GREY }}
    >
      {bookData && bookDataEtc ? (
        <View column style={{ padding: sizes.SIDE_PADDING }}>
          <BookInfo bookInfo={bookData.bookInfo} />
        </View>
      ) : null}
    </Layout>
  );
};

export default Book;
