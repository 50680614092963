export interface State {
  user: any;
  isInitialized: boolean;
}

export type Action =
  | {
      type: "INITIALIZE";
      payload: { user: any };
    }
  | {
      type: "SET_USER";
      payload: { user: any };
    }
  | { type: "SIGN_OUT" };

export const initialState: State = {
  user: null,
  isInitialized: false,
};

const applyLogout = (state: State, action: Action) => {
  if (action.type === "SIGN_OUT") {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        ...state,
        user: action.payload.user,
        isInitialized: true,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload.user,
      };
    case "SIGN_OUT":
      return applyLogout(state, action);

    default:
      return state;
  }
};

export default reducer;
