import React, { useCallback } from "react";
// components
import Header from "./headers/Header";
import BookHeader from "./headers/BookHeader";
import Footer from "./Footer";

interface Props {
  children: any;
  headerType?: string;
  style?: {};
  headerProps?: any;
}
const Layout = ({ children, headerType, style, headerProps }: Props) => {
  const renderHeader = useCallback(() => {
    switch (headerType) {
      case "book":
        return <BookHeader {...headerProps} />;
      default:
        return <Header {...headerProps} />;
    }
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100%" }}
    >
      {renderHeader()}
      <div style={style}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
