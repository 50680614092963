import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { isMobile, isMacOs, isIOS } from "react-device-detect";
// api
import useBLibraryAPI, { GetBLibraryDetailRes } from "api/etc/useBLibraryAPI";
// common
import MetaTags from "common/MetaTags";
// hooks
import useQuery from "hooks/useQuery";
// styles
import { Layout, View, Icon, BCurationCard, Button } from "styles/index";
import * as sizes from "styles/sizes";
import * as colors from "styles/colors";
import { H, P } from "styles/typography";
import Media, { media } from "styles/media";

const BLibraryDetail = () => {
  const query = useQuery();
  const bLibraryId = query?.id ? Number(query.id) : 0;

  const [bLibrary, setBLibrary] = useState<GetBLibraryDetailRes | null>(null);
  const bookList = bLibrary?.bookList || [];

  const { getBLibraryDetailAPI } = useBLibraryAPI();

  async function handleGetBLibraryDetail() {
    try {
      const newBLibrary = await getBLibraryDetailAPI(bLibraryId);
      setBLibrary(newBLibrary);
    } catch (error) {
      console.log(error);
    }
  }

  function getOpenLink() {
    if (isMobile) {
      return `https://bibly.page.link/?ibi=com%2Eryencatchers%2Ebibly&isi=1524931439&apn=com%2Eryencatchers%2Ebibly&link=https%3A%2F%2Fbibly%2Ekr%2FBLibraryDetail%3FcurationId%3D${bLibraryId}`;
    } else {
      if (isIOS || isMacOs) {
        return "https://apps.apple.com/kr/app/id1524931439";
      } else {
        return "https://play.google.com/store/apps/details?id=com.ryencatchers.bibly";
      }
    }
  }

  useEffect(() => {
    handleGetBLibraryDetail();
  }, []);

  return (
    <Layout>
      <MetaTags
        title={bLibrary?.influencerName || ""}
        description={bLibrary?.introduce || ""}
      />
      {bLibrary ? (
        <Container>
          <Intro>
            <CoverImg src={bLibrary?.influencerImage} />
            <div>
              <H f40>{bLibrary?.influencerName}</H>
              <P f24 style={{ color: colors.GREY_DARK }}>
                {bLibrary?.influencerJob}
              </P>
              <P f20 style={{ lineHeight: "28px" }}>
                {bLibrary?.introduce}
              </P>
            </div>
          </Intro>
          <Content>
            <View column center>
              <Media>
                {({ mobile }) => (
                  <>
                    <Icon
                      iconName="quotes-start-gray"
                      size={mobile ? 18 : 24}
                    />
                    <Title f40>{bLibrary?.curationTitle || ""}</Title>
                    <Icon
                      iconName="quotes-end-gray"
                      size={mobile ? 18 : 24}
                      style={{ marginBottom: mobile ? 40 : 60 }}
                    />
                  </>
                )}
              </Media>
              <P f18 style={{ lineHeight: "28px" }}>
                {bLibrary?.curationContent || ""}
              </P>
              <Divider />
              <BookListContainer wrap>
                {bookList.map((book, index) => (
                  <BCurationCard
                    key={String(book.bookId)}
                    book={book}
                    index={index}
                  />
                ))}
              </BookListContainer>
              <Button
                onClick={() => {
                  window.open(getOpenLink());
                }}
                style={{ width: "100%", maxWidth: 600, margin: "60px 0px" }}
                textStyle={{ fontSize: 20 }}
              >
                앱에서 확인하기
              </Button>
            </View>
          </Content>
        </Container>
      ) : null}
    </Layout>
  );
};

export default BLibraryDetail;

const Container = styled.div`
  max-width: 1200px;
  margin: 0px auto;
`;
const Intro = styled.div`
  display: flex;

  ${media.lessThan("desktop")`
    flex-direction: column;
    align-items: center;
  `}

  div {
    background-color: ${colors.BACKGROUND_GREY};
    padding: 40px;
    margin-left: 40px;
    ${media.lessThan("desktop")`
      margin: 40px 20px 0 20px;
    `}
    ${media.lessThan("mobile")`
      padding: 24px;
    `}
  }
  h1 {
    margin-bottom: 16px;
    ${media.lessThan("mobile")`
      margin-bottom: 8px;
      font-size: 32px;
    `}
  }
  p {
    margin-bottom: 16px;
    ${media.lessThan("mobile")`
      margin-bottom: 8px;
      font-size: 18px;
    `}
  }
`;
const CoverImg = styled.img`
  max-width: 580px;
  max-height: 580px;
`;
const Content = styled.div`
  padding: 60px 0px 40px 0px;
  ${media.lessThan("desktop")`
    padding: 40px 20px;
  `}
  ${media.lessThan("mobile")`
    padding: ${sizes.SIDE_PADDING};
  `}
`;
const Divider = styled.div`
  width: 1px;
  height: 120px;
  background-color: ${colors.BORDER_GREY};
  margin: 32px 0px 32px 0px;
`;

const Title = styled(H)`
  line-height: 38px;
  text-align: center;
  margin: 16px 0px;
  ${media.lessThan("desktop")`
    font-size: 32px;
    margin: 8px 0px;
  `}
  ${media.lessThan("mobile")`
    font-size: 24px;
  `}
`;
const BookListContainer = styled(View)`
  width: 100%;
  ${media.lessThan("desktop")`
    justify-content: center;
  `}
`;
