import React from "react";

const BLibrary = () => {
  return (
    <div>
      <div>BLibrary page</div>
    </div>
  );
};

export default BLibrary;
