export interface State {
  isLoading: boolean;
}

export type Action = {type: 'LOADING_START'} | {type: 'LOADING_END'};

const initialState: State = {
  isLoading: false,
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'LOADING_START':
      return {...state, isLoading: true};
    case 'LOADING_END':
      return {...state, isLoading: false};
    default:
      return state;
  }
};

export default reducer;
