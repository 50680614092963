import React from "react";
// styles
import { View, FOF } from "styles/index";
import { H } from "styles/typography";

export default function () {
  return (
    <View
      center
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <FOF />
      <H f24>페이지를 찾을 수 없습니다.</H>
    </View>
  );
}
