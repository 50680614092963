import React, { useEffect } from "react";

// const DURATION = 500;
const withInitialize = (WrappedComponent: any) => (props: any) => {
  async function init() {}

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <WrappedComponent {...props} />
    </>
  );
};

export default withInitialize;
