// apis
import useAPI from "../useAPI";
// types
import { Book, BCuration } from "../types";

export interface GetBCurationDetailRes {
  curationId: number;
  title: string;
  content: string;
  curationImage: string;
  onOrderYn?: boolean;
  bookList: Book[];
}

export default function useBCurationAPI() {
  const { getHandler } = useAPI();

  async function getBCurationsAPI(params: {
    page: number;
  }): Promise<BCuration[]> {
    try {
      const res = await getHandler("/etc/bibly-curations", params);
      return res.data.result.bCurationList;
    } catch (error) {
      throw error;
    }
  }

  async function getBCurationDetailAPI(
    curationId: number
  ): Promise<GetBCurationDetailRes> {
    try {
      const res = await getHandler(`/etc/bibly-curation/${curationId}`, {});
      return res.data.result.bCurationInfo;
    } catch (error) {
      throw error;
    }
  }

  return {
    getBCurationsAPI,
    getBCurationDetailAPI,
  };
}
