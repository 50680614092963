import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
// hooks
import useQuery from "hooks/useQuery";
// styles
import { Button, View } from "styles/index";
import * as sizes from "styles/sizes";
import * as colors from "styles/colors";
import { P } from "styles/typography";
import logo from "common/assets/imgs/logo.png";
import img1url from "common/assets/imgs/transferOldBibly/transfer-old-bibly-1.png";
import img2url from "common/assets/imgs/transferOldBibly/transfer-old-bibly-2.png";
import img3url from "common/assets/imgs/transferOldBibly/transfer-old-bibly-3.png";
import img4url from "common/assets/imgs/transferOldBibly/transfer-old-bibly-4.png";

const TransferOldBibly = () => {
  const query = useQuery();
  const oldBiblyUserId = query?.bu_num ? Number(query.bu_num) : 0;

  return (
    <Container>
      <Helmet>
        <title>비블리 데이터 이전</title>
      </Helmet>
      <img src={img1url} alt="img1" style={{ width: "100%", height: "auto" }} />
      <img src={img2url} alt="img2" style={{ width: "100%", height: "auto" }} />
      <img src={img3url} alt="img3" style={{ width: "100%", height: "auto" }} />
      <img src={img4url} alt="img4" style={{ width: "100%", height: "auto" }} />
      <ButtonContainer>
        <Button
          style={{ width: "100%" }}
          onClick={() => {
            if (oldBiblyUserId) {
              window.open(
                `https://bibly.page.link/?ibi=com%2Eryencatchers%2Ebibly&isi=1524931439&apn=com%2Eryencatchers%2Ebibly&link=https%3A%2F%2Fbibly%2Ekr%2FTransferOldBibly%3FoldBiblyUserId%3D${oldBiblyUserId}`
              );
            }
          }}
          customChildren={
            <View alignCenter>
              <img
                src={logo}
                alt="logo"
                style={{ width: 24, height: 24, marginRight: 4 }}
              />
              <P f18 style={{ color: colors.WHITE }}>
                설치하고 서재 옮기러 갈래요
              </P>
            </View>
          }
        />
      </ButtonContainer>
    </Container>
  );
};

export default TransferOldBibly;

const Container = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding-bottom: 100px;
`;
const ButtonContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${sizes.SIDE_PADDING};
`;
