// apis
import useAPI from "../useAPI";
// types
import { Book, BLibrary } from "../types";

export interface GetBLibraryDetailRes {
  curationId: number;
  onOrderYn: number;
  authorId: number;
  userId: number;
  influencerId: number;
  influencerImage: string;
  influencerName: string;
  influencerJob: string;
  introduce: string;
  curationTitle: string;
  curationSubtitle: string;
  curationContent: string;
  bookList: Book[];
  authorBookList: Book[];
}

export default function useBLibraryAPI() {
  const { getHandler } = useAPI();

  async function getBLibrariesAPI(params: {
    page: number;
  }): Promise<BLibrary[]> {
    try {
      const res = await getHandler("/etc/bibly-librarys", params);
      return res.data.result.bLibraryList;
    } catch (error) {
      throw error;
    }
  }

  async function getBLibraryDetailAPI(
    curationId: number
  ): Promise<GetBLibraryDetailRes> {
    try {
      const res = await getHandler(`/etc/bibly-library/${curationId}`, {});
      return res.data.result.bLibraryInfo;
    } catch (error) {
      throw error;
    }
  }

  return {
    getBLibrariesAPI,
    getBLibraryDetailAPI,
  };
}
