import React from "react";
// redux
import useLoadingRedux from "reduxModules/loading/useLoadingRedux";
// styles
import { Loading } from "styles/index";

const withLoading = (WrappedComponent: any) => (props: any) => {
  const {
    state: { isLoading },
  } = useLoadingRedux();

  return (
    <>
      <WrappedComponent {...props} />
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99999,
          }}
        >
          <Loading white size={60} />
        </div>
      ) : null}
    </>
  );
};

export default withLoading;
