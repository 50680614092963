import React from "react";
// styles
import { Divider, View } from "styles/index";
import * as colors from "styles/colors";
import { H, P } from "styles/typography";
// types
import { BookInfo as BookInfoType } from "api/types";

interface Props {
  bookInfo: BookInfoType;
}
const BookInfo = ({ bookInfo }: Props) => {
  return (
    <>
      <View justifyCenter>
        <img
          src={bookInfo.bookImage}
          alt="img"
          style={{
            width: "64%",
            marginBottom: 24,
            borderRadius: 10,
            border: `1px solid ${colors.WHITE}`,
          }}
        />
      </View>
      <H f18 style={{ marginBottom: 4 }}>
        {bookInfo.bookName}
      </H>
      <P f14 style={{ color: colors.GREY_DARK, marginBottom: 8 }}>
        {bookInfo.bookSubName}
      </P>
      <View alignCenter>
        <P f14 style={{ paddingRight: 8 }}>
          {bookInfo.authorName}
        </P>
        <div
          style={{
            height: 8,
            width: 1,
            backgroundColor: colors.GREY_LIGHT,
          }}
        />
        <P f14 style={{ paddingLeft: 8 }}>
          {bookInfo?.publisherName} {bookInfo?.publishDate}
        </P>
      </View>
      <Divider style={{ margin: "16px 0px" }} />
    </>
  );
};

export default BookInfo;
