import React from "react";
import { Helmet } from "react-helmet-async";

interface Props {
  title?: string;
  description?: string;
}
export default function MetaTags({ title, description }: Props) {
  return (
    <Helmet>
      <title>{title || ""}</title>
      <meta name="description" content={description || ""} />
    </Helmet>
  );
}
