import React from "react";
// styles
import { View } from "styles/index";
import * as colors from "styles/colors";
import * as sizes from "styles/sizes";
import { P } from "styles/typography";

const Footer = () => {
  return (
    <View
      justifyCenter
      style={{
        height: sizes.FOOTER_HEIGHT,
        backgroundColor: colors.PRIMARY,
        padding: "0 40px",
        marginTop: "auto",
      }}
    >
      <View justifyBetween alignCenter style={{ width: 1200, maxWidth: 1200 }}>
        <P f14 style={{ color: "rgba(255,255,255,0.7)" }}>
          &copy; Ryencatchers.
        </P>
        <P f14 style={{ color: "rgba(255,255,255,0.7)" }}>
          대한민국
        </P>
      </View>
    </View>
  );
};

export default Footer;
