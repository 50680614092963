import React from "react";
import styled from "styled-components";
// styles
import { Layout } from "styles/index";
import { H } from "styles/typography";

const Main = () => {
  return (
    <Layout>
      <Container>
        <H f20 style={{ marginBottom: 20 }}>
          Bibly 2.0 Web Page
        </H>
        {/* <P
          onClick={() => push("/blibrary/detail?id=14")}
          style={{ padding: 5 }}
        >
          비서재 예시
        </P>
        <P
          onClick={() => push("/bcuration/detail?id=3494207")}
          style={{ padding: 5 }}
        >
          큐레이션 예시
        </P>
        <P onClick={() => push("/transfer-old-bibly?bu_num=12345")} style={{ padding: 5 }}>
          구비블리 이전 랜딩페이지
        </P> */}
      </Container>
    </Layout>
  );
};

export default Main;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
