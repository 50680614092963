import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// types
import { State } from "./index";

export default function useLoadingRedux() {
  const dispatch = useDispatch();
  const state: State = useSelector((store) => store.loading, []);

  const start = useCallback(() => dispatch({ type: "LOADING_START" }), [
    dispatch,
  ]);

  const end = useCallback(() => dispatch({ type: "LOADING_END" }), [dispatch]);

  return {
    state,
    loadingDispatch: {
      start,
      end,
    },
  };
}
