import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// pages
import Main from "components/Main/index";
import Book from "components/Book/index";
import BCuration from "components/BCuration/index";
import BCurationDetail from "components/BCuration/BCurationDetail";
import BLibrary from "components/BLibrary/index";
import BLibraryDetail from "components/BLibrary/BLibraryDetail";
import TransferOldBibly from "components/TransferOldBibly";
import FOF from "components/FOF";

function Root() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Main />
        </Route>
        <Route exact path="/book">
          <Book />
        </Route>
        <Route exact path="/bcuration">
          <BCuration />
        </Route>
        <Route exact path="/bcuration/detail">
          <BCurationDetail />
        </Route>
        <Route exact path="/blibrary">
          <BLibrary />
        </Route>
        <Route exact path="/blibrary/detail">
          <BLibraryDetail />
        </Route>
        <Route exact path="/transfer-old-bibly">
          <TransferOldBibly />
        </Route>
        <Route>
          <FOF />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Root;
